<template>
  <v-container fluid class="onboarding bg-loop pa-0 pt-10" :class="{'mobile align-start': $vuetify.breakpoint.smAndDown}">

    <!-- <template v-if="isSaving">
    <v-progress-linear indeterminate></v-progress-linear>
  </template> -->

    <v-toolbar color="grey lighten-3" class="px-0 app-bar" absolute>
      <v-toolbar-title v-if="stepper === 1" class="pl-2 d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 d-inline-flex"
          contain
          src="@/assets/Logo-Color.png"
          transition="scale-transition"
          width="45"
        />
        Create Your Profile</v-toolbar-title>

        <template v-if="stepper === 2">
          <v-btn :text="$vuetify.breakpoint.smAndDown ? true : false" :dark="$vuetify.breakpoint.smAndDown ? false : true" class="ml-md-2" color="grey darken-1" @click="stepper = 1"><v-icon>mdi-arrow-left</v-icon> Details</v-btn>
        </template>

        <template v-if="stepper === 3">
          <v-btn :text="$vuetify.breakpoint.smAndDown ? true : false" :dark="$vuetify.breakpoint.smAndDown ? false : true" class="ml-md-2" color="grey darken-1" @click="stepper = 2"><v-icon>mdi-arrow-left</v-icon> Skills</v-btn>
        </template>

      <v-spacer></v-spacer>

      <v-toolbar-title v-if="stepper > 1" class="pl-2 d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 d-inline-flex"
          contain
          src="@/assets/Logo-Color.png"
          transition="scale-transition"
          width="45"
        />
      </v-toolbar-title>
      <v-spacer v-if="stepper > 1"></v-spacer>

       <template v-if="stepper === 1">
         <v-btn :text="$vuetify.breakpoint.smAndDown ? true : false" class="mr-md-2" color="primary" @click="updateUser('page1', 2, null)">Add Skills <v-icon>mdi-arrow-right</v-icon></v-btn>
       </template>
       <template v-if="stepper === 2">
         <v-btn :text="$vuetify.breakpoint.smAndDown ? true : false" class="mr-md-2" color="primary" @click="updateUser('page2', 3, null)">Social <v-icon>mdi-arrow-right</v-icon></v-btn>
       </template>
       <template v-if="stepper === 3">
         <v-btn :text="$vuetify.breakpoint.smAndDown ? true : false" class="mr-md-2" color="primary" @click="updateUser('page2', 4, true)">Save <v-icon class="ml-2">mdi-account-plus</v-icon></v-btn>
       </template>

    </v-toolbar>


  <v-stepper alt-labels v-model="stepper" class="col-12 col-md-10 col-lg-8 mx-md-auto my-12 my-md-16">
      <!-- <v-stepper-header class="bg-loop">
        <v-stepper-step step="1">
          Profile
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          Rate/Skills
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Social
          <small v-if="$vuetify.breakpoint.smAndUp">optional</small>
        </v-stepper-step>
      </v-stepper-header> -->


      <!-- Step 1 -->
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-2 pt-md-6 pb-0 ">
          <ValidationObserver tag="form" ref="page1" v-slot="{invalid}">
            <v-row>
              <v-col cols="12" md="5" align="start" jsutify="start">
                <div class="photo-container text-center py-4">

                  <h5 class="app-heading mb-1">Show us your face <v-icon>mdi-emoticon-cool-outline</v-icon></h5>
                  <div class="profile-photo">
                    <template v-if="!$isUndefined(photoURL.cropped)">
                      <img :src="`${photoURL.cropped}?version=1`" alt="" class="profile-img" @click="dialog = true">
                      <v-btn
                      color="white"
                      class="edit-photo"
                      @click="dialog = true"
                      small
                      rounded
                      >
                      edit image
                    </v-btn>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-avatar tile size="180" color="grey lighten-3" class="temp-image headline black--text rounded-xl" @click="dialog = true">
                        <!-- <v-icon size="60">mdi-emoticon-cool-outline</v-icon> -->
                        <v-icon size="60" color="primary">mdi-image-plus</v-icon>
                      </v-avatar>
                      <div><small>Choose Profile Photo ☝️</small></div>
                    </div>
                  </template>
                  <upload-image v-if="dialog" v-on:uploaded-photos="uploadedPhotos" @closeModal="closeModal"></upload-image>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input type="text" style="visibility:hidden; position:absolute; z-index:-1" v-model="photoURL.cropped">
                    <div class="error--text" v-if="errors[0]">{{errors[0]}}</div>
                  </ValidationProvider>
                </div>
              </div>
              </v-col>

              <v-col cols="12" md="5">
                <!-- <v-divider class="my-4 d-sm-none"></v-divider> -->

                <div class="name">
                  <h5 class="app-heading mb-2 text-center">Add Personal Details</h5>
                  <v-row class="mb-2" align="start" justify="center">
                    <v-col cols="10">
                      <ValidationProvider rules="required|min:2" v-slot="{ errors }">
                        <v-text-field
                        class="prefix"
                        placeholder="Name"
                        prefix="First"
                        solo hide-details="auto"
                        v-model.trim="localUser.firstName"
                        :error-messages="errors[0]"
                        background-color="grey lighten-3" flat
                        ></v-text-field>
                      </ValidationProvider>

                      <div class="mb-4"></div>

                      <ValidationProvider rules="required|min:2" v-slot="{ errors }">
                        <v-text-field
                          class="prefix"
                          placeholder="Name"
                          prefix="Last"
                          solo hide-details="auto"
                          v-model="localUser.lastName"
                          :error-messages="errors[0]"
                          background-color="grey lighten-3" flat
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </div>

                <!-- <v-divider class="my-4"></v-divider> -->

                <div class="">
                  <h5 class="app-heading mb-0 text-center">Choose a username.</h5>
                  <p class="text-center">
                    <span class="text--disabled">meetable.me/@{{localUser.userName || '???????'}}</span>
                  </p>
                  <ValidationProvider rules="required|unique" :debounce="500" v-slot="{ errors }">
                    <v-row class="mb-2" align="start" justify="center">
                        <!-- <img src="@/assets/at-sign.svg" alt="" class="at-sign"> -->
                        <!-- <svg class="at-sign" xmlns:x="http://ns.adobe.com/Extensibility/1.0/" xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/" xmlns:graph="http://ns.adobe.com/Graphs/1.0/" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve"><switch><foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"/><g i:extraneous="self"><path d="M70.343,91.344C62.544,94.836,55.784,96,46.555,96C24.978,96,6,80.611,6,55.267C6,28.888,25.368,5.999,54.614,5.999    C77.622,5.999,94,21.646,94,43.37c0,19.009-10.659,30.905-24.697,30.905c-6.109,0-10.528-3.233-11.179-9.957h-0.26    c-4.16,6.466-9.879,9.957-16.898,9.957c-8.319,0-14.558-6.336-14.558-16.939c0-15.905,11.829-30.13,30.546-30.13    c5.719,0,12.218,1.423,15.338,3.104l-3.899,24.052c-1.17,7.63-0.26,11.121,3.38,11.25c5.589,0.259,12.608-6.854,12.608-21.854    c0-16.938-10.919-29.87-31.066-29.87c-20.018,0-37.306,15.388-37.306,40.215c0,21.725,13.778,33.88,33.146,33.88    c6.629,0,13.779-1.422,18.978-4.009L70.343,91.344z M58.904,37.939c-1.04-0.259-2.47-0.518-4.03-0.518    c-8.579,0-15.339,8.405-15.339,18.361c0,4.915,2.21,8.018,6.37,8.018c4.939,0,10.009-6.077,11.049-13.577L58.904,37.939z"/></g></switch></svg> -->

                      <v-col cols="8" class="pl-0">
                        <v-text-field
                        placeholder="personName"
                        prefix="@"
                        solo hide-details="auto"
                        v-model.trim="localUser.userName"
                        :error-messages="errors[0]"
                        background-color="grey lighten-3" flat
                        @focus="setUserName"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" class="mt-2">
                        <v-icon size="30" v-if="!$isEmpty(errors[0])">mdi-cancel</v-icon>
                        <v-icon size="30" v-if="!$isUndefined(localUser.userName)" color="success" class="mt-2">mdi-checkbox-marked-circle</v-icon>
                      </v-col>
                    </v-row>
                    <small v-if="errors[0] && !$isUndefined(localUser.userName) "></small>
                  </ValidationProvider>
                </div>

              </v-col>
            </v-row>




          </ValidationObserver>
        </v-stepper-content>


        <v-stepper-content step="2" class="pa-2">
          <ValidationObserver  ref="page2" v-slot="{invalid}">

            <div class="mb-8">
              <ValidationProvider rules="required" v-slot="{errors}">
                <v-row  class="mb-2">
                  <v-col cols="12" class="pb-0">
                    <h5 class="app-heading mb-0">What's your time worth?</h5>
                    <div class="bg-loop white--text d-flex align-center rounded justify-center py-2">
                      <v-icon color="white">mdi-currency-usd</v-icon>

                        <imask-input
                        class="rate"
                          v-model="localUser.rate"
                          :mask="currency"
                          :unmask="'typed'"
                        >

                        </imask-input>

                      <strong class="pl-2">/ hr</strong>
                    </div>
                    <p class="text--secondary text-body-2 mb-1">Our community grows when everyone's time is worth something.</p>
                  </v-col>
                  <v-col cols="12" v-if="!$isEmpty(errors)" class="text-center">
                      <div class="error lighten-4 pa-3">
                        <span class="error--text">{{ errors[0] }}</span>
                      </div>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </div>

            <div class="">
              <!-- <v-divider class=" my-5"></v-divider> -->
              <h5 class="app-heading mb-1">What can people meet you about?</h5>
              <!-- <p class="text--secondary text-body-2 mb-1">Type to <i>search</i> for skills or <i>add</i> your own</p> -->
              <v-combobox
                class="skills"
                color="primary"
                v-model="skills"
                :items.sync="skillsListSorted"
                item-text="name"
                item-value="name"
                :search-input.sync="search"
                prepend-icon="mdi-database-search"
                chips
                multiple
                placeholder="Baking, Presentations, Cycling, ect..."
                label="Skills/Expertise"
                solo background-color="grey lighten-3"
                return-object
              >
                <!-- <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    color="black"
                    dark
                    @click:close="removeItem(item)"
                  >
                    <strong class="">{{ item.name }}</strong>&nbsp;
                  </v-chip>
                </template> -->
                <template v-slot:item="{item, index}">
                    <span class="" ><v-icon size="14" color="green" class="mr-2" v-if="inSkillsList(item.name)">mdi-check</v-icon> {{ item.name }}</span>&nbsp;
                </template>

                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text">No results matching "<strong>{{ search }}</strong>".</span> Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>

              <div class="skills ">
                <v-row no-gutters class="text--disabled text-caption">
                  <v-col cols="4">skill / interest</v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" class="text-center">meetings</v-col>
                  <v-col cols="2"  class="text-center">rating</v-col>
                  <v-col cols="auto" class="ml-3 ml-md-6">remove</v-col>
                </v-row>
                <v-row class="skill pa-1" no-gutters v-for="(skill, index) in localUser.skills" :key="`skill-${index}`" align="center" justify="start">
                  <v-col cols="6"><v-chip class="" color="primary">{{skill.name}}</v-chip></v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" align-items="center">
                    <v-icon class="mr-1" size="16" color="grey">mdi-handshake-outline</v-icon>{{skills.meetings || 0}} <br>
                  </v-col>
                  <v-col cols="2" align-items="center"><v-icon class="mr-1" size="16" color="grey">mdi-arrow-up-down</v-icon>{{skills.rating || 0}}</v-col>
                  <v-col cols="auto"><v-btn color="error" @click="localUser.skills.splice(index, 1)" icon><v-icon>mdi-close-circle-outline</v-icon></v-btn></v-col>
                </v-row>
              </div>
            </div>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-2">

          <h2 class="text-h5 my-2 text-center text-md-left secondary--text">Social Proof</h2>
          <p class="mb-6 text--secondary text-center text-md-left">Listing your accomplishments, companies, or social network links will make people excited to meet you.</p>

          <div class="">
            <h5 class="app-heading text-center text-md-left">Add Profile Bio</h5>
            <v-textarea
            solo flat
            background-color="grey lighten-3"
            auto-grow
            label="Description"
            rows="1"
            row-height="20"
            v-model.trim="localUser.description"
            :value="localUser.description"
            ></v-textarea>
          </div>

          <div class="mb-8">
            <h5 class="app-heading text-center text-md-left">Add Social Links</h5>
            <v-row  class="mb-3 mx-0 pa-2 "  no-gutters v-for="(link, index) in localUser.links" :key="`link-${index}`">

            <v-col>
              <ValidationProvider rules="required|url" v-slot="{ errors }">
                <v-text-field
                  dense
                  :error-messages="errors[0]"
                  placeholder="https://website.com"
                  filled hide-details="auto"
                  v-model.trim="link.url"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="removeLink" icon><v-icon>mdi-cancel</v-icon></v-btn>
            </v-col>
          </v-row>
          <div class="">
            <v-btn small text color="primary" @click="addLink"><v-icon class="mr-2">mdi-comment-plus-outline</v-icon> Add Social Link</v-btn>
          </div>
        </div>
        </v-stepper-content>

        <v-stepper-content step="4" class="pa-2">

        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>



    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      dark
    >
      {{ snackbar.message }}
      <v-btn
        text
        color="white"
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>




    <v-snackbar
      v-model="skillsSnackbar.show"
      :color="skillsSnackbar.color"
      dark
    >
      {{ skillsSnackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          color="white"
          v-bind="attrs"
          @click="skillsSnackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


  </v-container>
</template>

<script>
const uploadImage = () => import("@/components/shared/upload-image.vue");
import { cloud, db } from '@/firebase';
import { mapGetters } from 'vuex'
import {IMaskComponent} from 'vue-imask';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

//RULE: required
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  message:'This field is required.',
  computesRequired: true
});
//RULE: rate
extend('rate', {
  validate(value) {
    return
  },
  message:'This field is required.',
  computesRequired: true
});
//RULE: min:2
extend('min', {
  validate(value, args) {
    return value.length >= args.length;
  },
  message:'Must be at least 2 letters long',
  params: ['length']
});
//RULE: url
extend('url', {
  validate(value){
    const regexp = new RegExp('^(https?:\\/\\/)?'+ // protocol
   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            if (regexp.test(value))return true
            else return false
  },
  message:'Not a valid url?'
});


export default {
  name: "onboarding",
  components:{
    uploadImage, ValidationProvider, ValidationObserver,
    'imask-input': IMaskComponent
  },
  firestore: {
    skillsList: db.collection('skills').orderBy('name'),
  },
  computed:{
     getPercentages(arr){
        return true;
    },
    skillsListSorted(){
      function compare(a, b) {
        return a.name > b.name ? 1: -1
      }
      return this.skillsList.slice().sort(compare);
    },
    // photoURL: function (){ return {cropped:'things'}},
    ...mapGetters({
      user:'GET_USER',
      userId:'GET_USER_ID',
      photoURL: 'GET_PHOTO_LOCATION',
    }),
  },
  watch:{
    skills: function (value, prev){
      let vThis = this;
      if(!vThis.$isEmpty(value)){
        // process.env.NODE_ENV === "development" ? console.log( 'real value...', value[0]) : null;

        let val = null;
        if(vThis.$isUndefined(value[0].name)){val = value[0]}
        else{val = value[0].name}

        let existsAlready = vThis.localUser.skills.findIndex(function(post){
          // console.log(val, post.name,post.name == val);
          if (post.name == val){
            return true
          }
        });

        if(existsAlready == -1){
          let v = {
            name: val.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
            value: val.split(" ").join(""),
            rating: null,
            meetings: null,
            new:true,
            valid:true,
          }
          vThis.localUser.skills.push(v)
          vThis.skillsList.push({name:v.name, valid: true})
        }
        else{
          vThis.skillsSnackbar.show = true;
        }
        vThis.skills = [];
      }
    },
  },
  data(){
    return{
      stepper:1,
      currency:[{
        // mask: 'd0000',
        lazy: false,
        // blocks: {
        //   d: {
            mask: Number,
            thousandsSeparator: ',',
            scale: 2,
            signed: false,
            min:1,
            max:500
        //   },
        // },
      }],
      search:null,
      searchSkills: null,
      skillsSnackbar: {
        show:false,
        color: 'red',
        message: 'Skill Already Chosen'
      },
      skills:[],
      skillsList:[],
      socialNetworks:[
        {text: 'facebook', icon: 'mdi-facebook'},
        {text: 'Instagram', icon: 'mdi-twitter'},
        {text: 'Twitter', icon: 'mdi-instagram'},
        {text: 'Youtube', icon: 'mdi-youtube'},
        {text: 'LinkedIn', icon: 'mdi-linkedin'},
        {text: 'Website', icon: 'mdi-web'},
      ],
      calAuthUrl:false,
      isSaving:false,
      isLoaded: false,
      isNameLoading: false,
      localUser: {
        skills:[],
        links:{},
      },
      dialog:false,
      snackbar:{
        show:false,
      },
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },

  methods:{
    addLink(){
      this.localUser['links'].push({})
    },
    // async getCalAuth(){
    //     let getCalendarAuth = cloud.httpsCallable('getCalendarAuth');
    //     let token = await getCalendarAuth().then(function(result) {
    //       process.env.NODE_ENV === "development" ? console.log( 'results from getCalendarAuth', result ) : null;
    //       return result.data;
    //     }).catch(function(error) {
    //       process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
    //     });
    //     return token
    // },
    // addCalendar(){
    //   let vThis = this;
    //   this.getCalAuth().then(url => {
    //     vThis.calAuthUrl = url;
    //   })
    // },
    setUp(){
      this.$store.dispatch('getCurrentUser').then((results)=>{
        this.localUser = this.$clone(results.data);
        this.skills = results.data.skills
        // this.photoURL = results.data.Photo

        if(!this.$isEmpty(results.data.photoURL) || this.$isUndefined(results.data.photoURL.cropped)){
          let data = results.data.photoURL;
          this.$store.dispatch('storePhotos', {data})
        }
        if(this.$isEmpty(results.data.links)){
          this.localUser.links.push({})
        }
        process.env.NODE_ENV === "development" ? console.log( 'results.data.name', results.data ) : null;
        if(!this.$isEmpty(results.data.name) || !this.$isUndefined(results.data.name)){
          let name = results.data.name.split(' ');
          process.env.NODE_ENV === "development" ? console.log( 'name', name ) : null;
          this.localUser.firstName = name[0];
          this.localUser.lastName = name[1];
        }

      }).catch((err)=>{
        console.log('error', err);
      });
    },
    remove (item) {
        const index = this.skillsList.indexOf(item.name)
        if (index >= 0) this.skillsList.splice(index, 1)
    },
    finalizeSetup(){
      process.env.NODE_ENV === "development" ? console.log( Object.hasOwnProperty.call(this.user, 'avatar') ) : null;
      if(!Object.hasOwnProperty.call(this.user, 'avatar')){
        this.saveAvatar(this.localUser,true)
      }
      else{

        this.$router.push({name:'build'})
      }
    },
    uploadedPhotos(data){
      process.env.NODE_ENV === "development" ? console.log( 'did photos update?', data ) : null;
      this.localUser.photoURL[data.location] = data.url;
    },
    closeModal(data){
      process.env.NODE_ENV === "development" ? console.log( 'modal closed', data ) : null;
      this.dialog = data;
    },
    updateUser(observer, step, isFinal){
      let vThis = this;

      vThis.$refs[`${observer}`].validate().then(results =>{
        if(results === true){
          //start loading for saving
          vThis.stepper = step;

          if(isFinal !== null){
            vThis.isSaving = true;
            vThis.localUser['photoURL'] = JSON.parse(JSON.stringify(vThis.photoURL))
            vThis.localUser.showProfileFirst = false;
            vThis.photoURL;
            //upload to server
            vThis.addCloudSkills()
            .then((x)=> vThis.cloudUpdate().then(x=>{
              process.env.NODE_ENV === "development" ? console.log( 'x',x ) : null;
              vThis.stepper = step;
              vThis.$router.push('/my')
            }));
          }
        }
      })
    },
    async cloudUpdate(){
      let vThis = this;
      let upload = await db.collection('users').doc(vThis.userId).update(vThis.localUser)
      return upload;
    },
    removeLink(item){
      process.env.NODE_ENV === "development" ? console.log( 'item', item ) : null;
      this.localUser.links.splice(this.localUser.links.indexOf(item), 1)
      this.localUser.links = [...this.localUser.links]
    },
    removeItem(item){
        this.skills.splice(this.skills.indexOf(item), 1)
        this.skills = [...this.skills]
    },
    inSkillsList(item){
      let vThis = this;
      if(!this.$isUndefined(item)){
        // process.env.NODE_ENV === "development" ? console.log( 'item', item ) : null;
        let result = vThis.localUser.skills.findIndex(function(skill){
          // process.env.NODE_ENV === "development" ? console.log( 'skill', skill.name, skill.name == item ) : null;
          if (skill.name == item){
            return true;
          }
        });
        // process.env.NODE_ENV === "development" ? console.log( 'is it working?', result ) : null;
        return result == -1 ? false : true;
      }
    },
    async addCloudSkills(){
      let vThis = this;
      const promises = [];
      vThis.localUser.skills.forEach(async (skill, index) =>{
        if(!vThis.$isUndefined(skill.new)){
          process.env.NODE_ENV === "development" ? console.log( 'skill new.....', skill ) : null;
          delete skill.new
          let added = await vThis.putSkill(skill)
          promises.push(added)
        }
      })
      return promises;
    },
    async putSkill(skill){
      // return true
      let addNewSkill = cloud.httpsCallable('addNewSkill');
      let addedSkill = await addNewSkill({skill:skill}).then(function(result) {
        process.env.NODE_ENV === "development" ? console.log( 'results from addCloudSkill', result ) : null;
        return result.data;
      }).catch(function(error) {
        process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
        return {error:error}
      });
      return addedSkill;
    },
    async isUsernameUnique() {
      let vThis = this;
      let isUniqueUserName = cloud.httpsCallable('isUniqueUserName');
      if(vThis.user.userName === vThis.localUser.userName){
        return true;
      }
      else{
        vThis.isNameLoading = true;
        let checked = await isUniqueUserName({userName:vThis.localUser.userName}).then(function(result) {
          process.env.NODE_ENV === "development" ? console.log( 'result, local, user, if', result.data.message) : null;
          if(result.data.message === true){
            return true
          }
          else{
            return false
          }
        }).catch(function(error) {
          process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
          return false
        });
        vThis.isNameLoading = false;
        return checked;
      }
    },
    async isPhotoLoaded(){
      let vThis = this;
      process.env.NODE_ENV === "development" ? console.log( 'photo debug, is empty, is undefined' ) : null;
      process.env.NODE_ENV === "development" ? console.log( vThis.$isEmpty(vThis.photoURL) ) : null;
      process.env.NODE_ENV === "development" ? console.log( vThis.$isUndefined(vThis.photoURL.cropped) ) : null;
      if(!vThis.$isEmpty(vThis.photoURL) ||  !vThis.$isUndefined(vThis.photoURL.cropped)){
        return true
      }
      else{return false}

    },
    setUserName(event){
      // process.env.NODE_ENV === "development" ? console.log( event ) : null;
      // process.env.NODE_ENV === "development" ? console.log('this.localUser.firstName this.localUser.lastName', this.localUser.firstName, this.localUser.lastName, !this.$isUndefined(this.localUser.firstName), !this.$isUndefined(this.localUser.lastName) ) : null;
      if(!this.$isUndefined(this.localUser.firstName) && !this.$isUndefined(this.localUser.lastName)){
        if(this.$isUndefined(this.localUser.userName)){
          this.localUser.userName = `${this.localUser.firstName}${this.localUser.lastName}`
        }
      }
    }
  },
  created(){
    let vThis = this;
    this.setUp(this.user)
  },
  mounted(){
    extend("unique", {
        validate: this.isUsernameUnique,
        message: "Username already taken"
      });
    extend("photo", {
        validate: this.isPhotoLoaded,
        message: "Photo needed"
      });
      // this.setUp(this.user)

  }
};
</script>


<style lang="scss">
.container.onboarding{
  background-color: #f2f2f2;
  position: relative;
  min-height: 100vh;
  padding-top: 70px;
  align-items: center;
  display: flex;
  &:before {
      content: "";
      background-image: url("~@/assets/meetable-tile2.png");
      background-repeat: repeat;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.6;
      z-index: 0;
    }

  > form{
    width:100%;
  }
  .app-bar{
    width:100vw;
    .v-toolbar__content{
      padding: 0;
    }
  }

  .v-stepper{
    width:100%;
    .v-stepper__step{
      flex-basis:auto!important;
      &.v-stepper__step--active{

        .v-stepper__step__step{
          background-color:white!important;
          color: black!important;
        }
        .v-stepper__label{
          color:white!important;
        }
      }
      .v-stepper__label{
        display: block!important;
        font-weight: 700!important;
      }
    }
  }


  .profile-photo{
      position: relative;
    .edit-photo{
      margin: auto;
      position: absolute;
      bottom:20%;
      left: 45%;
    }
  }

  .temp-image{
    border: 2px dashed #aaa;
  }

  .profile-img{
    max-width: 100%;
    border-radius: 6px;
    border: 3px solid white;
    img{
      max-width:100%;
    }
  }
  h2{
    line-height: 95%;
  }
  .company-links{
    text-decoration: none;
  }

  .rate-field{
    min-width: 40px;
    max-width: 60px!important;
    .v-text-field__details{
      display: none;
    }
    .v-input__slot{
      padding: 0 5px!important;
      margin:0!important;
      input{
        // text-align:right;
      }
    }
  }

  .rate{
    font-size: 24px;
    color:black;
    font-weight: bold;
    width:60px;
    background-color: white;
    padding: 2px;
    border-radius: 6px;
  }

  .skills{
    .skill{
      border-bottom: 1px solid #f2f2f2;
    }
  }

  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }

  &.mobile{
    // padding-top:0;
      align-items: start!important;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
    .v-stepper{
      border-radius:0;
    }
    .profile-photo{
      max-width: 50vw;
      margin: 0 auto;
    }
  }

.v-text-field{
  &.prefix{
    .v-text-field__prefix{
      width:60px;
      text-align: left;
    }
  }
}

}
</style>
